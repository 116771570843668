<template>
  <div :class="$style.select">
    <div v-if="type === 'single'">
      <div v-if="select.length" :class="$style.box">
        <img :src="select[0]" alt="" />
        <div :class="$style.bg">
          <i :class="$style.eye" class="ri-eye-line" @click="onPreview(select[0])" />
          <a-upload
            name="file"
            :action="BASE_URL + '/api/cultural/file/upload'"
            :headers="headers"
            :show-upload-list="false"
            accept="image/jpg, image/jpeg, image/png"
            @change="handleChange"
          >
            <i :class="$style.edit" class="ri-edit-2-line" />
          </a-upload>
        </div>
      </div>
      <div v-else :class="$style.add">
        <a-upload
          name="file"
          :action="BASE_URL + '/api/cultural/file/upload'"
          :headers="headers"
          :show-upload-list="false"
          @change="handleChange"
        >
          <i class="ri-add-line" />
        </a-upload>
      </div>
    </div>
    <div v-if="type === 'multiple'" :class="$style.multiple">
      <div v-if="show" :class="$style.add">
        <a-upload
          name="file"
          :action="BASE_URL + '/api/cultural/file/upload'"
          :headers="headers"
          :show-upload-list="false"
          @change="handleChange"
        >
          <i class="ri-add-line" />
        </a-upload>
      </div>
      <div v-for="(item, index) in select" :key="index" :class="$style.box">
        <img :src="item" alt="" />
        <div :class="$style.bg">
          <i :class="$style.eye" class="ri-eye-line" @click="onPreview(item)" />
          <i :class="$style.delete" class="ri-delete-bin-7-line" @click="onDelete(index)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { BASE_URL } from '@/utils/variable'

export default {
  name: 'uploadAvatar',
  props: {
    select: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: 'single',
      validator: val => ['single', 'multiple'].includes(val),
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      BASE_URL,
    }
  },
  computed: {
    ...mapState(['token']),
    headers() {
      return { Authorization: this.token }
    },
    show() {
      if (!this.limit) {
        return true
      } else {
        return this.select.length !== this.limit
      }
    },
  },
  methods: {
    handleChange(info) {
      const status = info.file.status
      if (status === 'done') {
        if (!info.file.response.code) {
          this.$message.error(info.file.response.error)
          return
        }
        this.$message.success('上传成功')
        this.$emit('complete', info.file.response.data.path)
      } else if (status === 'error') {
        this.$message.error('服务器异常')
      }
    },
    onPreview(item) {
      let tmp = this.select.map(item => ({
        type: 'image',
        cover: item,
      }))
      this.BjPreview(tmp, item)
    },
    onDelete(index) {
      this.$emit('delete', index)
    },
  },
}
</script>

<style lang="less" module>
.select {
  .box {
    position: relative;
    width: 70px;
    height: 70px;
    overflow: hidden;

    img {
      width: 70px;
      height: 70px;
      object-fit: cover;
    }
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: none;
    width: 100%;
    height: 100%;
    line-height: 70px;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);

    i {
      font-size: 18px;
    }

    i:nth-child(2) {
      margin-left: 12px;
    }

    .delete {
      color: @error-color;
    }

    .edit {
      color: #fff;
    }

    // .edit:hover {
    //   color: @primary-color;
    // }
    .eye {
      color: #fff;
    }

    // .eye:hover {
    //   color: @primary-color;
    // }
  }

  .box:hover .bg {
    display: block;
  }

  .add {
    width: 70px;
    height: 70px;
    line-height: 80px;
    text-align: center;
    border: 2px dashed #eee;
    cursor: pointer;

    i {
      color: #5c5c5c;
      font-size: 30px;
    }
  }

  .multiple {
    display: flex;
    flex-wrap: wrap;

    .box {
      margin-right: 12px;
      margin-bottom: 10px;
    }

    .add {
      margin-right: 12px;
      margin-bottom: 10px;
    }
  }
}
</style>
