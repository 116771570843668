<template>
  <div :class="$style.add">
    <div v-if="step === 0">
      <div v-if="type === 'modify'" :class="$style.info">
        <p :class="$style.title">主体变更</p>
        <div :class="$style.box">
          <div :class="$style.left">
            <span class="required">*</span>
            主体变更协议
          </div>
          <div :class="$style.right">
            <uploadMaterial :select="add.change_cert" @complete="certChange" />
            <div :class="$style.tip">
              请
              <span class="primary cursor">下载模板</span>
              ，填写完成并盖章。上传原件照片、扫描件或者加盖公章的复印件；大小不超过2M；格式支持JPG、PNG。
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.info">
        <p :class="$style.title">资质信息</p>
        <div :class="$style.box">
          <div :class="$style.left">
            <span class="required">*</span>
            机构类型
          </div>
          <div :class="$style.right">
            <bj-cascader
              v-model="add.organization_type"
              :options="organizationData"
              :disabled="type === 'edit'"
              :field-names="{
                label: 'name',
                value: 'id',
                children: 'children',
              }"
              :allow-clear="false"
              placeholder="请选择机构类型"
            />
            <div :class="$style.tip">请根据实际情况确认机构类型，根据不同的机构类型上传不同的证件。</div>
          </div>
        </div>

        <div :class="$style.box">
          <div :class="$style.left">
            <span class="required">*</span>
            机构名称
          </div>
          <div :class="$style.right">
            <BjInput v-model="add.certificate_name" :disabled="type === 'edit'" placeholder="请输入机构名称" />
            <div :class="$style.tip">机构名称需严格按照证件填写，在认证通过后不可更改。</div>
          </div>
        </div>

        <div :class="$style.box">
          <div :class="$style.left">
            <span class="required">*</span>
            机构代码
          </div>
          <div :class="$style.right">
            <BjInput v-model="add.certificate_code" :disabled="type === 'edit'" placeholder="请输入机构代码" />
            <div :class="$style.tip">机构代码需严格按照证件填写，在认证通过后不可更改。</div>
          </div>
        </div>

        <div :class="$style.box">
          <div :class="$style.left">
            <span class="required">*</span>
            所在地区
          </div>
          <div :class="$style.right">
            <bj-cascader
              v-model="area"
              :options="areaList"
              :field-names="{
                label: 'area_name',
                value: 'area_id',
                children: 'children',
              }"
              placeholder="请选择所在地区"
              :allow-clear="false"
              :load-data="loadData"
            />
          </div>
        </div>

        <div :class="$style.box">
          <div :class="$style.left">
            <span class="required">*</span>
            组织机构代码证
          </div>
          <div :class="$style.right">
            <uploadMaterial :select="add.certificate" @complete="certificateChange" />
            <div :class="$style.tip">请上传原件照片、扫描件或者加盖公章的复印件；大小不超过2M；格式支持JPG、PNG。</div>
          </div>
        </div>

        <div :class="$style.box">
          <div :class="$style.left">其他证明材料（选填）</div>
          <div :class="$style.right">
            <uploadMaterial
              :select="add.others_certs"
              :limit="10"
              type="multiple"
              @complete="otherChange"
              @delete="otherDelete"
            />
            <div :class="[$style.tip, $style.mt0]">
              特殊情况可上传作为佐证，如企业工商变更证明、官方红头文件、加盖公章证明等。其他证明材料可上传最多10张；大小不超过2M；格式支持JPG、PNG。
            </div>
          </div>
        </div>
        <!-- ———————— -->
      </div>

      <div :class="$style.info">
        <p :class="$style.title">法人/负责人信息</p>
        <div :class="$style.box">
          <div :class="$style.left">
            <span class="required">*</span>
            姓名
          </div>
          <div :class="$style.right">
            <BjInput v-model="add.corporate_name" placeholder="请输入法人/负责人姓名" />
          </div>
        </div>

        <div :class="$style.box">
          <div :class="$style.left">
            <span class="required">*</span>
            证件类型
          </div>
          <div :class="$style.right">
            <BjSelect
              v-model="add.corporate_type"
              key-field="id"
              placeholder="请选择证件类型"
              value-field="id"
              label-field="name"
              show-all
              :options="corporateData"
            />
          </div>
        </div>

        <div :class="$style.box">
          <div :class="$style.left">
            <span class="required">*</span>
            证件号码
          </div>
          <div :class="$style.right">
            <BjInput v-model="add.corporate_number" placeholder="请输入法人/负责人证件号码" />
          </div>
        </div>
      </div>

      <div :class="[$style.info, $style.phone]">
        <p :class="$style.title">联系人信息</p>
        <div :class="$style.box">
          <div :class="$style.left">
            <span class="required">*</span>
            联系人姓名
          </div>
          <div :class="$style.right">
            <BjInput v-model="add.contacts" placeholder="请输入联系人姓名" />
          </div>
        </div>

        <div :class="$style.box">
          <div :class="$style.left">
            <span class="required">*</span>
            联系人电话
          </div>
          <div :class="$style.right">
            <BjInput v-model="add.contacts_tel" placeholder="请输入联系人电话" />
          </div>
        </div>

        <div :class="$style.box">
          <div :class="$style.left">
            <span class="required">*</span>
            验证码
          </div>
          <div :class="$style.right">
            <BjInput v-model="add.contacts_tel_code" placeholder="请输入你上面联系人电话获取的短信验证码">
              <span v-if="!finish" slot="suffix" class="tag" @click="getCode()">
                {{ time === 0 ? '没收到？重新发送' : '获取验证码' }}
              </span>
              <span v-else slot="suffix" class="tag">{{ time }}秒后可重新获取</span>
            </BjInput>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step === 1" :class="$style.step1">
      <div :class="$style.examine">
        <img :src="oss + '/manage/icon/default/auth.png'" alt="" />
        <p :class="$style.stitle">平台正在审核你提交的资料</p>
        <p :class="$style.tip">资料提交之后一般会在 7 个工作日内审核你的认证信息，请耐心等待！</p>
      </div>
    </div>
    <div v-if="type !== 'edit'" :class="$style.footer" :style="{ width: footWidth, left: left }">
      <div v-if="step === 0" :class="$style.btns">
        <BjButton class="btn-default" @click="back()"><i class="ri-logout-box-line left" />退出认证</BjButton>
        <BjButton type="primary" @click="onSave()"><i class="ri-arrow-right-line left" />下一步</BjButton>
      </div>
      <div v-else :class="$style.btns">
        <BjButton class="btn-default" @click="backStep()"><i class="ri-arrow-left-line left" />返回</BjButton>
      </div>
      <div :class="$style.steps">
        <a-steps :class="$style.step" size="small" :current="step">
          <a-step title="填写资料" />
          <a-step title="等待审核" />
        </a-steps>
      </div>
    </div>
    <div v-else :class="$style.footer" :style="{ width: footWidth, left: left }">
      <BjButton type="primary" @click="eidtSave()">更新信息</BjButton>
    </div>
  </div>
</template>

<script>
import uploadMaterial from '@/components/uploadMaterial'
import { platformService } from '@/service'
import { areaLevel } from '@/utils/variable'

const service = new platformService()

export default {
  name: 'Add',
  components: {
    uploadMaterial,
  },
  data() {
    return {
      finish: false,
      time: null,
      step: 0,
      type: 'add',
      organizationData: [],
      areaList: [],
      area: [],
      add: {
        organization_type: [],
        certificate_name: null,
        certificate: [],
        others_certs: [],
        corporate_type: undefined,
        valid_key: null,
        corporate_name: null,
        corporate_number: null,
        contacts_tel: null,
        change_cert: [],
        contacts_tel_code: null,
      },
      corporateData: [
        {
          name: '中国大陆居民身份证',
          id: 1,
        },
        {
          name: '中国香港居民来往内地通行证',
          id: 2,
        },
        {
          name: '中国澳门居民来往内地通行证',
          id: 3,
        },
        {
          name: '中国台湾居民来往内地通行证',
          id: 4,
        },
        {
          name: '其他国家或地区居民护照',
          id: 5,
        },
      ],
    }
  },
  computed: {
    collapsed: {
      get() {
        return this.$store.state.collapsed
      },
      set(val) {
        this.$store.commit('COLLAPSED', val)
      },
    },
    sideChirdShow: {
      get() {
        return this.$store.state.sideChirdShow
      },
      set(val) {
        this.$store.commit('SIDECHIRDSHOW', val)
      },
    },
    footWidth() {
      let left = this.collapsed ? (this.sideChirdShow ? '260px' : '100px') : this.sideChirdShow ? '340px' : '180px'
      let w = 'calc(100% - ' + left + ')'
      return w
    },
    left() {
      let left = this.collapsed ? (this.sideChirdShow ? '240px' : '80px') : this.sideChirdShow ? '320px' : '160px'
      return left
    },
  },
  created() {
    this.getType()
    this.type = this.$route.query.type
    this.add.valid_key = this.$route.query.key
    // 直接添加进来的 add
    if (this.$route.query.type === 'add') {
      // this.getRegion()
      // this.getDetail()
      this.getArea()
    }
    // 直接添加进来的 小修改
    if (this.$route.query.type === 'edit') {
      this.getDetail()
    }
    // 直接添加进来的 大修改
    if (this.$route.query.type === 'modify') {
      this.getDetail()
    }
  },
  methods: {
    async getDetail() {
      try {
        const { data } = await service.getAuthentication()
        this.add = data.certificate
        this.area = [this.add.province_id, this.add.city_id, this.add.county_id]
        this.add.organization_type = this.add.organization_type_ids
        this.getArea()
      } catch (e) {}
    },
    async getType() {
      try {
        const { data } = await service.organizationType()
        this.organizationData = data
      } catch (e) {}
    },
    // async getRegion() {
    //   const { data } = await service.getArea({ area_type: 'province' })
    //   this.areaList = data
    //   this.areaList.map(item => {
    //     item.isLeaf = false
    //     return item
    //   })
    // },
    async getArea() {
      try {
        const { data } = await service.getArea({ area_type: 'province' })
        this.areaList = data
        this.areaList.map(item => {
          item.isLeaf = false
          return item
        })
        this.areaList.map(async item => {
          if (item.area_id === this.add.province_id) {
            const cdata = await service.getArea({
              area_type: areaLevel[1],
              parent_area_id: item.area_id,
            })
            item.children = cdata.data
            item.children.map(async item => {
              if (item.area_id === this.add.city_id) {
                const ccdata = await service.getArea({
                  area_type: areaLevel[2],
                  parent_area_id: item.area_id,
                })
                item.children = ccdata.data
                this.area = [this.add.province_id, this.add.city_id, this.add.county_id]
              }
            })
          }
        })
      } catch (e) {}
    },
    async loadData(e) {
      const targetOption = e[e.length - 1]
      targetOption.loading = true
      const { data } = await service.getArea({
        area_type: areaLevel[e.length],
        parent_area_id: targetOption.area_id,
      })
      targetOption.loading = false
      targetOption.children = data
      if (e.length !== 2) {
        targetOption.children.map(item => {
          item.isLeaf = false
          return item
        })
      }
      this.areaList = [...this.areaList]
    },
    certificateChange(path) {
      this.add.certificate = [path]
    },
    otherChange(path) {
      this.add.others_certs.push(path)
    },
    otherDelete(index) {
      this.add.others_certs.splice(index, 1)
    },
    certChange(path) {
      this.add.change_cert = [path]
    },
    async getCode() {
      if (!this.add.contacts_tel) {
        this.$message.error('请输入联系人电话')
        return
      }
      if (this.time) {
        return
      }
      let timer = null
      try {
        await service.getValidCode({
          username: this.add.contacts_tel,
          template: 'code',
          is_valid_register: 0,
        })
        this.finish = true
        this.time = 60
        timer = setInterval(() => {
          if (this.time !== 0) {
            this.time--
          } else {
            clearInterval(timer)
            this.finish = false
          }
        }, 1000)
        this.$message.success('带有验证码的信息已经通过你填写的手机号码发送给你，请注意查收。')
      } catch (e) {}
    },
    async onSave() {
      try {
        if (this.area.length !== 3) {
          this.$message.error('请选择所在地区')
          return
        }
        if (!this.add.organization_type.length) {
          this.$message.error('请选择机构类型')
          return
        }
        await service.saveAuthentication({
          ...this.add,
          province_id: this.area[0],
          city_id: this.area[1],
          county_id: this.area[2],
          organization_type: this.add.organization_type[this.add.organization_type.length - 1],
          change_status: this.type === 'modify' ? 1 : 0,
        })
        this.$message.success('保存成功')
        this.step = 1
      } catch (e) {}
    },
    async eidtSave() {
      try {
        if (this.area.length !== 3) {
          this.$message.error('请选择所在地区')
          return
        }
        await service.saveAuthentication({
          ...this.add,
          province_id: this.area[0],
          city_id: this.area[1],
          county_id: this.area[2],
          organization_type: this.add.organization_type[this.add.organization_type.length - 1],
          change_status: 0,
        })
        this.$message.success('保存成功')
      } catch (e) {}
    },
    back() {
      this.$router.push({
        name: 'systemPlatform',
      })
    },
    backStep() {
      this.$router.push({
        name: 'systemPlatform',
      })
    },
  },
}
</script>

<style lang="less" module>
.add {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .step1 {
    position: relative;
    margin-top: 200px;
    text-align: center;

    .examine {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .stitle {
        margin-bottom: 10px;
        color: #000;
        font-size: 14px;
      }

      img {
        width: 60px;
        height: 60px;
        margin-bottom: 20px;
      }
    }
  }

  .info {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #fff;

    .title {
      color: #000;
      font-size: 16px;
    }
  }

  .tip {
    margin-top: 10px;
    color: #5c5c5c;
    font-size: 12px;
  }

  .box {
    display: flex;
    margin-bottom: 20px;

    .left {
      width: 140px;
      padding-top: 8px;
      padding-right: 55px;
      color: #000;
    }

    .right {
      width: 600px;
    }
  }

  .phone {
    margin-bottom: 60px;
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 15px;
    z-index: 99;
    display: flex;
    align-items: center;
    // width: calc(100% - 30px);
    height: 60px;
    padding: 10px;
    background: #fff;
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);

    .btns {
      width: 280px;

      button {
        margin: 0 10px;
      }

      i {
        font-size: 18px;
        vertical-align: -4px;
      }
    }

    .steps {
      flex: 1;

      .step {
        width: 250px;
        margin-left: auto;
      }

      :global {
        .ant-steps-item-title {
          font-size: 14px;
        }
      }
    }
  }
}

.mt0 {
  margin-top: 0 !important;
}
</style>
